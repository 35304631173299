import Base from "formiojs/components/_classes/component/Component";
import editForm from "formiojs/components/table/Table.form";
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
import $ from "jquery";
import { Formio } from "formiojs";
import * as _lodash from "lodash";

export default class DocumentCustomeViewerComponent extends Base {
    constructor(component, options, data) {
        var formOptions = Formio.getPlugin("optionsPlugin");
        var customOptions = _lodash.default.merge(options, formOptions.options);
        if (customOptions.core == null || customOptions.core == undefined) {
            console.log(customOptions);
        }
        super(component, customOptions, data);
        component.core = customOptions.core;
        component.appId = customOptions.appId;
        component.uiUrl = customOptions.uiUrl;
        component.wrapperUrl = customOptions.wrapperUrl;
        this.form = this.getRoot();
        this.signCompleted = false;
        var that = this;
        this.data = data;
        this.documentsList = [];
    }
    //disable
    static schema(...extend) {
        return Base.schema({
            label: "documentcustomeviewer",
            type: "documentcustomeviewer",
        },
            ...extend
        );
    }
    setValue(value) {
        this.value = JSON.stringify(value);
        this.dataValue = JSON.stringify(value);
    }
    get defaultSchema() {
        return DocumentCustomeViewerComponent.schema();
    }
    static builderInfo = {
        title: "Document Signer",
        group: "basic",
        icon: "fa fa-file",
        weight: 70,
        schema: DocumentCustomeViewerComponent.schema(),
    };
    getUrl(file, type) {
        var name = this.getName();
        var downloadUrl;
        var url;
        var icon;
        var disableView;
        var component = this.component;
        if (type == "pdf") {
            url =
                component.wrapperUrl +
                "app/" +
                component.appId +
                "/document/" +
                name +
                "?docPath=" +
                file.file;
            if (file && file.file_url) {
                url =
                    component.wrapperUrl +
                    "app/" +
                    component.appId +
                    "/document/" +
                    name +
                    "?docPath=" +
                    file.file_url;
                downloadUrl =
                    component.wrapperUrl +
                    "app/" +
                    component.appId +
                    "/document/" +
                    name +
                    "?docPath=" +
                    file.file_url;
            } else {
                downloadUrl =
                    component.wrapperUrl +
                    "app/" +
                    component.appId +
                    "/document/" +
                    name +
                    "?docPath=" +
                    file.file;
            }
            icon = "<i class='fa fa-file-pdf-o'></i>";
            disableView = false;
        } else if (type == "png" || type == "jpeg" || type == "jpg") {
            url =
                component.wrapperUrl +
                "app/" +
                component.appId +
                "/document/" +
                name +
                "?docPath=" +
                file.file;
            if (file && file.file_url) {
                url = component.wrapperUrl + component.appId + "/" + file.file_url;
                downloadUrl =
                    component.wrapperUrl +
                    "app/" +
                    component.appId +
                    "/document/" +
                    name +
                    "?docPath=" +
                    file.file_url;
            } else {
                downloadUrl = url;
            }
            icon = "<i class='fa fa-picture-o'></i>";
            disableView = false;
        } else {
            icon = "<i class='fa fa-file-o fileIcon'></i>";
            disableView = true;
        }
        if (typeof file) {
            downloadUrl =
                component.wrapperUrl +
                "app/" +
                component.appId +
                "/document/" +
                name +
                "?docPath=" +
                file.file;
            url =
                component.wrapperUrl +
                "app/" +
                component.appId +
                "/document/" +
                name +
                "?docPath=" +
                file.file;
        }
        return {
            url: url,
            downloadUrl: downloadUrl,
            icon: icon,
            disableView: disableView,
        };
    }
    getType(file) {
        if (file && file.originalName != undefined) {
            return file.originalName
                .substr(file.originalName.lastIndexOf(".") + 1)
                .toLowerCase();
        }
    }
    getName() {
        var form = this.form ? this.form : this;
        try {
            var name = this.form._data["documents"][
                this.component.index
            ].originalName;
        } catch (error) {
            this.redraw();
            return;
        }
        this.dataValue = name;
        return name;
    }
    bindHandlers() {
        var that = this;
        var form = that.form ? that.form : that;
        var component = this.component;
        var name = that.getName();
        if (!name) {
            return;
        }
        if (this.previousValue == this.dataValue && document.getElementsByClassName("customDocumentView" + this.dataValue)[0]) {
            return;
        }
        //this.component.documentsList 
        this.previousValue = this.dataValue;
        var file = form._data["documents"][this.component.index];
        var type = that.getType(file);
        var url, icon, downloadUrl;
        var variables = that.getUrl(file, type);
        url = variables.url;
        var disableView = variables.disableView;
        downloadUrl = variables.downloadUrl;
        icon = variables.icon;
        that.documentsList =
            `<div class="docList customDocumentView` + this.dataValue + `" style="margin:0;" key="` +
            this.id +
            `">
      <div class="fileDiv">
      <a href="`+ downloadUrl + `" target="_blank"><button style="background-color: #E10098; font-size: 12px;"` +
            ` class="btn btn-sm btn-info ` +
            `-selectFile" title="Click to preview the document" >
      <b>Download Safety Performance History Records Request Form</b>
    </button></div></div>`;
        that.redraw();
    }
    render(children) {
        var documentsList = this.documentsList ?
            this.documentsList :
            `<p>not working</p>`;
        setTimeout(() => {
            this.bindHandlers();
        }, 2000);
        var row = `<button id="sign_btn_` + this.id + `" >Sign Form</button>`;
        row += `<div id="myModal_` + this.id + `" class="insuresign modal"></div>`;
        return super.render(`<div>${documentsList}</div>`);
    }

    static editForm = editForm;

    elementInfo() {
        const info = super.elementInfo();
        info.type = "input";
        info.attr.type = "hidden";
        info.changeEvent = "change";
        return info;
    }
    build(element) {
        super.build(element);
    }
}