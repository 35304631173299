const colors = {
    "uuid1" : "#ff141e",
    "uuid2" : "#ffab40",
    "uuid3" : "#25d2ff",
    "uuid4" : "Grey",
    "uuid5" : "#1c61ba"
  }

export default function colorHandler(code){

      return colors[code]
}