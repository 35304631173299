import React from 'react';
import Swal from "sweetalert2";
import MapRender from './MapRender';

export default class Mapbox extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            lng: 0,
            lat: 0,
            zoom: 15,
            summary: [],
            driver: props.config.driver,
            trip: props.config.trip,
            fleet: props.config.fleet,
            color:props.config.color
        };
    }

    getTripSummary(driver, trip, fleet) {
        let ts = [];
        let url = "https://hdoapi.eoxvantage.com/prod/v1/mapbox"; 
        // Constructing the request body as JSON
        const requestBody = {
            driver_id: driver,
            trip: trip,
            fleet_id: fleet
        };
    
        fetch(url, {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify(requestBody) // Sending the request body as JSON string
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Network response was not ok.');
            }
        }).then((data) => {
            if (data.trip_id == trip) {
                if (data.simple_path) {
                    let i = 0;
                    data.simple_path.map(co => {
                        if (i === 0) {
                            this.setState({ lng: co.longitude, lat: co.latitude });
                        }
                        i++;
                        ts.push([co.longitude, co.latitude ]);
                    });
                    this.setState({ summary: ts });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Could not get the trip summary information! ",
                });
            }
        }).catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Could not fetch trip summary information! ",
            });
        });
    }

    componentDidMount() {
        let mapData = this.props.data[0];
        if (mapData[this.state.driver] !== null && mapData[this.state.trip] !== null) {
            this.getTripSummary(mapData[this.state.driver], mapData[this.state.trip], mapData[this.state.fleet]);
        }
    }

    render() {
        return (
            <div>
                <MapRender
                    latitude={this.state.lat} key={Math.random()} longitude={this.state.lng} summary={this.state.summary} color={this.state.color} data={this.props.data} canvasElement={this.props.canvasElement}
                />
            </div>
        );
    }
}
